"use client";
import {
  Box,
  Flex,
  Image,
  HStack,
  Stack,
  IconButton,
  useDisclosure,
  useColorModeValue,
  Container,
} from "@chakra-ui/react";
import Link from "next/link";
import Logo from "../logo.png";
// Here we have used react-icons package for the icons
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import NavLink from "./NavLink";
import { usePathname } from "next/navigation";
//import SearchInput from "../pagesComponents/SearchInput";

const navLinks = [
  { name: "Все Магазины", path: "/merchants/" },
  { name: "Промокоды", path: "/promokody/" },
  { name: "Категории", path: "/categories/" },
];

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const pathname = usePathname();
  console.log(pathname);
  return (
    <Box
      px={4}
      boxShadow="lg"
      position="fixed"
      width="100%"
      zIndex="55"
      top="0"
      bg={useColorModeValue("white", "gray.800")}
    >
      <Container maxW="72rem" p={{ base: 0, md: "inherit" }}>
        <Flex
          h={16}
          alignItems="center"
          justifyContent="space-between"
          maxW={"72rem"}
          mx="auto"
        >
          <IconButton
            size="md"
            icon={isOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}
            aria-label="Open Menu"
            display={["inherit", "inherit", "none"]}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems="center">
            <Link href="/" replace>
              <Image
                objectFit="cover"
                maxH={"40"}
                maxW={"150"}
                src="/public/logo.svg"
                alt="промокоды от rupromokod.ru"
              />
            </Link>
          </HStack>

          <HStack
            as="nav"
            spacing={1}
            display={{ base: "none", md: "flex" }}
            alignItems="center"
          >
            {navLinks.map((link, index) => (
              <NavLink
                key={index}
                {...link}
                onClose={onClose}
                current_path={pathname}
              />
            ))}
            {/* Dropdown Menu */}
          </HStack>
        </Flex>
      </Container>

      {/* Mobile Screen Links */}
      {isOpen ? (
        <Box pb={4} display={["inherit", "inherit", "none"]}>
          <Stack as="nav" spacing={2}>
            {navLinks.map((link, index) => (
              <NavLink
                key={index}
                {...link}
                onClose={onClose}
                current_path={pathname}
              />
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}
